import { useState } from "react";
import cn from "classnames";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomePage from "./HomePage/HomePage";
import SubmissionsPage from "./SubmissionsPage/SubmissionsPage";
import styles from "./global.module.scss";
import ArchivePage from "./ThreadPage/ThreadPage";
import AboutUsPage from "./AboutUsPage/AboutUsPage";
import SubmissionPage from "./SubmissionPage/SubmissionPage";
import CallForSoundsPage from "./CallForSounds/CallForSoundsPage";
import EventsPage from "./EventsPage/EventsPage";
import EventPage from "./EventPage/EventPage";
import PublicationPage from "./PublicationPage/PublicationPage";
import PublicationsPage from "./PublicationsPage/PublicationsPage";
// import CallForSoundsBanner from "./CallForSounds/CallForSoundsBanner/CallForSoundsBanner";

const App = () => {
  const [cursorStyles, setCursorStyles] = useState({});
  const { pathname } = window.location;

  const [cursorClickClass, setCursorClickClass] = useState("");

  const isDarkMode = pathname === "/call-for-sounds";
  // const [isCallForSoundsBannerOpen, setIsCallForSoundsBannerOpen] =
  //   useState(true);

  return (
    <div
      className={styles.appBody}
      onMouseMove={(e) => {
        setCursorStyles({
          top: `${e.pageY - 10}px`,
          left: `${e.pageX - 10}px`,
        });
      }}
      onClick={(e) => {
        setCursorClickClass("expand");

        setTimeout(() => {
          setCursorClickClass("");
        }, 500);
      }}
    >
      <div
        className={cn(styles.cursor, styles[cursorClickClass], {
          [styles.darkCursor]: isDarkMode,
        })}
        style={cursorStyles}
      />

      <Router>
        <Routes>
          <Route>
            <Route path="/" element={<HomePage />} />
            <Route path="/submit" element={<SubmissionsPage />} />
            {/* <Route path="/manifesto" element={<ManifestoPage />} /> */}
            <Route path="/thread" element={<ArchivePage />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/publications" element={<PublicationsPage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route
              path="/submission/:submissionId"
              element={<SubmissionPage />}
            />

            <Route path="/event/:eventId" element={<EventPage />} />
            <Route
              path="/publication/:publicationId"
              element={<PublicationPage />}
            />
          </Route>
          <Route path="/call-for-sounds" element={<CallForSoundsPage />} />
        </Routes>
      </Router>

      {/* {isCallForSoundsBannerOpen && (
        <CallForSoundsBanner
          setIsCallForSoundsBannerOpen={setIsCallForSoundsBannerOpen}
        />
      )} */}
    </div>
  );
};

export default App;
